import { SubtitleList } from "components/notification";
import styled from "styled-components";
import { getColor } from "@nef/style-utils";
import { Section } from "@nef/core";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { EST_TIMEZONE } from "utils/dateUtils";

dayjs.extend(utc);
dayjs.extend(timezone);

export const filterForDataArray = (dataArray: { label: string }[], substr: string) => {
  if (substr.length > 0) {
    return dataArray.filter(data => data.label.match(new RegExp(substr, "i")));
  }
  return dataArray;
};

export const REJ_PRICE_OO_RANGE = "!REJ - PRICE OUT OF RANGE";
export const REJ_PRICE_OO_OVERRIDE_RANGE = "!REJ - PRICE OUT OF OVERRIDE RANGE";

export const PV_REJECT_LABELS = {
  [REJ_PRICE_OO_RANGE]: "PRICE OO RANGE",
  [REJ_PRICE_OO_OVERRIDE_RANGE]: "PRICE OO OVERRIDE RANGE",
};

export type PV_REJECT_MESSAGE = typeof REJ_PRICE_OO_RANGE | typeof REJ_PRICE_OO_OVERRIDE_RANGE;

export type PorServiceErrorResponse = { message: string };

export const PV_REJECT_FIELD_PREFIX = "PV_REJECT_";

export enum PvRejectModel {
  ID = "id",
  ACT_DATE = "actDate",
  ACT_READY_FLAG = "actReadyFlag",
  ACT_TIME = "actTime",
  EXECUTION_DATE = "executionDate",
  EXECUTION_TIME = "executionTime",
  EXECUTION_TIME_NS = "executionTimeNs",
  CONTROL_NUM = "controlNum",
  ADVERTISEMENT_INSTRUCTION = "advertisementInstruction",
  AS_OF_FLAG = "asOfFlag",
  BREAK_STATE = "breakState",
  CARRY_OVER_FLAG = "carryOverFlag",
  CLEARING_FLAG = "clearingFlag",
  CLEARING_PRICE = "clearingPrice",
  CLEARING_STATUS = "clearingStatus",
  CONTRA_BRANCH_SEQ_NUM = "contraBranchSeqNum",
  CONTRA_CAPACITY = "contraCapacity",
  CONTRA_CLEARING_NUM = "contraClearingNum",
  CONTRA_EXEC_ID = "contraExecID",
  CONTRA_GIVEUP_MPID = "contraGiveUpMPID",
  CONTRA_MPID = "contraMPID",
  CONTRA_ORDER_ID = "contraOrderID",
  CONTRA_REF_NUM = "contraReferenceNum",
  CONTRA_SHORT_SALE_IND = "contraShortSaleInd",
  CONTRA_TRADE_REPORT_ID = "contraTradeReportID",
  CORRESPONDENT_MPID = "correspondentMPID",
  ENTRY_I1I2 = "entryI1I2",
  ENTRY_MPID = "entryMPID",
  ENTRY_METHOD_FLAG = "entryMethodFlag",
  ENTRY_SYMBOL = "entrySymbol",
  ENTRY_TRADE_MODS = "entryTradeModifiers",
  EXEC_ID = "execID",
  EXEC_BRANCH_SEQ_NUM = "executingBranchSeqNum",
  EXEC_CAPACITY = "executingCapacity",
  EXEC_CLEARING_NUM = "executingClearingNum",
  EXEC_GIVEUP_MPID = "executingGiveUpMPID",
  EXEC_MPID = "executingMPID",
  FEE = "fee",
  INTENDED_MARKET_FLAG = "intendedMarketFlag",
  LAST_UPDATE_DATE = "lastUpdateDate",
  LAST_UPDATE_I1I2 = "lastUpdateI1I2",
  LAST_UPDATE_TIME = "lastUpdateTime",
  LATE_TRADE_REPORT_FLAG = "lateTradeReportFlag",
  MATCH_CONTROL_NUM = "matchControlNum",
  MATCH_FLAG = "matchFlag",
  MEMO = "memo",
  MOD_2 = "modifier2",
  MOD_2_TIME = "modifier2Time",
  MOD_3 = "modifier3",
  MOD_4 = "modifier4",
  MOD_4_TIME = "modifier4Time",
  ENTRY_MOD_2 = "entryModifier2",
  ENTRY_MOD_3 = "entryModifier3",
  ENTRY_MOD_4 = "entryModifier4",
  NOTIONAL = "notional",
  ORDER_ID = "orderID",
  ORIGINAL_CONTROL_DATE = "originalControlDate",
  ORIGINAL_CONTROL_NUM = "originalControlNum",
  ORIGINAL_MARKET_FLAG = "originalMarketFlag",
  PREV_TRADE_STATUS = "previousTradeStatus",
  PRICE = "price",
  PRICE_OVERRIDE = "priceOverride",
  PRICE_TRADE_DIGIT = "priceTradeDigit",
  PUBLISH_FLAG = "publishFlag",
  QUANTITY = "quantity",
  REF_NUM = "referenceNum",
  REF_REPORTING_VENUE = "referenceReportingVenue",
  RELATED_MARKET_FLAG = "relatedMarketFlag",
  REPORTING_PARTY = "reportingParty",
  REVERSAL_FLAG = "reversalFlag",
  RISK_ELIGIBLE_FLAG = "riskEligibleFlag",
  SECURITY_CLASS = "securityClass",
  SELLER_DAYS = "sellerDays",
  SENT_TO_CLEARING = "sentToClearing",
  SETTLEMENT = "settlement",
  ENTRY_SETTLEMENT = "entrySettlement",
  SIDE = "side",
  SPECIAL = "special",
  SPECIAL_INSTRUCTION = "specialInstruction",
  SPECIAL_TRADE_FLAG = "specialTradeFlag",
  STEP_IN_OUT = "stepInOut",
  SYMBOL = "symbol",
  TRADE_MODS = "tradeModifiers",
  TRADE_REFERENCE_NUM = "tradeReferenceNum",
  TRADE_REPORT_FLAG = "tradeReportFlag",
  TRADE_REPORT_ID = "tradeReportID",
  TRADE_STATUS = "tradeStatus",
  TRADE_THROUGH_EXEMPT = "tradeThroughExempt",
  TRF = "trf",
  WORKSTATION_ACTION = "workstationAction",
  WORKSTATION_ID = "workstationID",
  WORKSTATION_USER_ID = "workstationUserId",
  MOD_2_TIME_NS = "modifier2TimeNs",
  MOD_4_TIME_NS = "modifier4TimeNs",
  STATUS = "status",
  REJECT_COUNT = "rejectCount",
  REJECT_SOURCE = "rejectSource",
  REJECT_TEXT = "rejectText",
  REJECT_STATUS = "rejectStatus",
}

export enum RejectStatus {
  Closed = "X",
  Repaired = "R",
  Updated = "U",
  Deleted = "D",
}

export type PvReject = {
  [PvRejectModel.ID]: number;
  [PvRejectModel.ACT_DATE]: string;
  [PvRejectModel.ACT_READY_FLAG]: string;
  [PvRejectModel.ACT_TIME]: string;
  [PvRejectModel.EXECUTION_DATE]: number;
  [PvRejectModel.EXECUTION_TIME]: number;
  [PvRejectModel.EXECUTION_TIME_NS]: number;
  [PvRejectModel.CONTROL_NUM]: string;
  [PvRejectModel.ADVERTISEMENT_INSTRUCTION]: string;
  [PvRejectModel.AS_OF_FLAG]: string;
  [PvRejectModel.BREAK_STATE]: string;
  [PvRejectModel.CARRY_OVER_FLAG]: string;
  [PvRejectModel.CLEARING_FLAG]: string;
  [PvRejectModel.CLEARING_PRICE]: string;
  [PvRejectModel.CLEARING_STATUS]: string;
  [PvRejectModel.CONTRA_BRANCH_SEQ_NUM]: string;
  [PvRejectModel.CONTRA_CAPACITY]: string;
  [PvRejectModel.CONTRA_CLEARING_NUM]: string;
  [PvRejectModel.CONTRA_EXEC_ID]: string;
  [PvRejectModel.CONTRA_GIVEUP_MPID]: string;
  [PvRejectModel.CONTRA_MPID]: string;
  [PvRejectModel.CONTRA_ORDER_ID]: string;
  [PvRejectModel.CONTRA_REF_NUM]: string;
  [PvRejectModel.CONTRA_SHORT_SALE_IND]: string;
  [PvRejectModel.CONTRA_TRADE_REPORT_ID]: string;
  [PvRejectModel.CORRESPONDENT_MPID]: string;
  [PvRejectModel.ENTRY_I1I2]: string;
  [PvRejectModel.ENTRY_MPID]: string;
  [PvRejectModel.ENTRY_METHOD_FLAG]: string;
  [PvRejectModel.ENTRY_SYMBOL]: string;
  [PvRejectModel.ENTRY_TRADE_MODS]: string;
  [PvRejectModel.EXEC_ID]: string;
  [PvRejectModel.EXEC_BRANCH_SEQ_NUM]: string;
  [PvRejectModel.EXEC_CAPACITY]: string;
  [PvRejectModel.EXEC_CLEARING_NUM]: string;
  [PvRejectModel.EXEC_GIVEUP_MPID]: string;
  [PvRejectModel.EXEC_MPID]: string;
  [PvRejectModel.FEE]: number;
  [PvRejectModel.INTENDED_MARKET_FLAG]: string;
  [PvRejectModel.LAST_UPDATE_DATE]: number;
  [PvRejectModel.LAST_UPDATE_I1I2]: string;
  [PvRejectModel.LAST_UPDATE_TIME]: number;
  [PvRejectModel.LATE_TRADE_REPORT_FLAG]: string;
  [PvRejectModel.MATCH_CONTROL_NUM]: string;
  [PvRejectModel.MATCH_FLAG]: string;
  [PvRejectModel.MEMO]: string;
  [PvRejectModel.MOD_2]: string;
  [PvRejectModel.MOD_2_TIME]: string;
  [PvRejectModel.MOD_3]: string;
  [PvRejectModel.MOD_4]: string;
  [PvRejectModel.MOD_4_TIME]: string;
  [PvRejectModel.ENTRY_MOD_2]: string;
  [PvRejectModel.ENTRY_MOD_3]: string;
  [PvRejectModel.ENTRY_MOD_4]: string;
  [PvRejectModel.NOTIONAL]: number;
  [PvRejectModel.ORDER_ID]: string;
  [PvRejectModel.ORIGINAL_CONTROL_DATE]: string;
  [PvRejectModel.ORIGINAL_CONTROL_NUM]: string;
  [PvRejectModel.ORIGINAL_MARKET_FLAG]: string;
  [PvRejectModel.PREV_TRADE_STATUS]: string;
  [PvRejectModel.PRICE]: number;
  [PvRejectModel.PRICE_OVERRIDE]: boolean;
  [PvRejectModel.PRICE_TRADE_DIGIT]: string;
  [PvRejectModel.PUBLISH_FLAG]: string;
  [PvRejectModel.QUANTITY]: number;
  [PvRejectModel.REF_NUM]: string;
  [PvRejectModel.REF_REPORTING_VENUE]: string;
  [PvRejectModel.RELATED_MARKET_FLAG]: string;
  [PvRejectModel.REPORTING_PARTY]: string;
  [PvRejectModel.REVERSAL_FLAG]: string;
  [PvRejectModel.RISK_ELIGIBLE_FLAG]: string;
  [PvRejectModel.SECURITY_CLASS]: string;
  [PvRejectModel.SELLER_DAYS]: string;
  [PvRejectModel.SENT_TO_CLEARING]: string;
  [PvRejectModel.SETTLEMENT]: string;
  [PvRejectModel.ENTRY_SETTLEMENT]: string;
  [PvRejectModel.SIDE]: string;
  [PvRejectModel.SPECIAL]: string;
  [PvRejectModel.SPECIAL_INSTRUCTION]: string;
  [PvRejectModel.SPECIAL_TRADE_FLAG]: string;
  [PvRejectModel.STEP_IN_OUT]: string;
  [PvRejectModel.SYMBOL]: string;
  [PvRejectModel.TRADE_MODS]: string;
  [PvRejectModel.TRADE_REFERENCE_NUM]: string;
  [PvRejectModel.TRADE_REPORT_FLAG]: string;
  [PvRejectModel.TRADE_REPORT_ID]: string;
  [PvRejectModel.TRADE_STATUS]: string;
  [PvRejectModel.TRADE_THROUGH_EXEMPT]: string;
  [PvRejectModel.TRF]: "1" | "2";
  [PvRejectModel.WORKSTATION_ACTION]: string;
  [PvRejectModel.WORKSTATION_ID]: string;
  [PvRejectModel.WORKSTATION_USER_ID]: string;
  [PvRejectModel.MOD_2_TIME_NS]: string;
  [PvRejectModel.MOD_4_TIME_NS]: string;
  [PvRejectModel.STATUS]: string;
  [PvRejectModel.REJECT_COUNT]: string;
  [PvRejectModel.REJECT_SOURCE]: string;
  [PvRejectModel.REJECT_TEXT]: string;
  [PvRejectModel.REJECT_STATUS]: RejectStatus | null;
};

export enum PORAction {
  APPROVE = "APPROVE",
  DENY = "DENY",
}

export enum PORStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  EXPIRED = "EXPIRED",
}

export enum PORModel {
  ID = "id",
  APPROVER_EMAIL = "approverEmail",
  APPROVER_ID = "approverId",
  SYMBOL = "symbol",
  MPID = "mpid",
  REJECTS = "rejects",
  REQUEST_STATUS = "status",
  SUBSCRIBED = "subscribed",
  REQUESTOR_EMAIL = "requestorEmail",
  REQUESTOR_ID = "requestorId",
  LAST_UDPATE = "lastUpdateDatetime",
  PENDING_TIME = "pendingDatetime",
  ACTION_TYPE = "actionType",
  ACTIONER_ID = "actionerId",
  ACTIONER_EMAIL = "actionerEmail",
  ACTIONED_TIME = "actionDateTime",
  EXPIRED_FROM = "expiredType",
  EXPIRED_TIME = "expiredDatetime",
}

export interface POR {
  [PORModel.ID]: string;
  [PORModel.APPROVER_EMAIL]: string;
  [PORModel.APPROVER_ID]: string;
  [PORModel.SYMBOL]: string;
  [PORModel.MPID]: string;
  [PORModel.REQUEST_STATUS]: PORStatus;
  [PORModel.SUBSCRIBED]: boolean;
  [PORModel.REQUESTOR_EMAIL]: string;
  [PORModel.REQUESTOR_ID]: string;
  [PORModel.LAST_UDPATE]: string;
  [PORModel.REJECTS]: number;
  [PORModel.PENDING_TIME]: number;
  [PORModel.ACTION_TYPE]: PORAction;
  [PORModel.ACTIONER_ID]: string;
  [PORModel.ACTIONER_EMAIL]: string;
  [PORModel.ACTIONED_TIME]: string;
  [PORModel.EXPIRED_FROM]: string;
  [PORModel.EXPIRED_TIME]: string;
}

type PorErrorDto = {
  por: POR;
  error: string;
};

export enum PorProcessManyStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PARTIAL = "PARTIAL",
}

export type PorProcessManyDto = {
  successful: POR[];
  failed: PorErrorDto[];
  status: PorProcessManyStatus;
};

export const getSaveAllSuccessMessage = (numSuccess: number) =>
  `Created ${numSuccess} POR(s) successfully.`;

export const getSaveAllFailedMessage = (numFailed: number) =>
  `Failed to create ${numFailed} POR(s).`;

export const getSaveAllErrorSubtitle = (errorMessages: string[] | undefined) => {
  if (Array.isArray(errorMessages) && errorMessages.length > 0) {
    return (
      <SubtitleList
        messages={errorMessages}
        maxNumShown={2}
        additionalMessageOnMax={`${errorMessages.length - 2} other errors`}
      />
    );
  }
  return "Reason for failures is unknown.";
};

export enum DATE_FILTER {
  ALL = "ALL",
  TODAY = "TODAY",
}

export const ArraySection = styled(Section)`
  ${props => `
    background-color: ${getColor("gray", 0)(props)};
    height: 100%;
    overflow: hidden;
    padding: 1rem !important;
    box-sizing: border-box;
    grid-column: span 1;
  `}
`;

export const ArrayWrapper = styled.div`
  padding-right: 1rem;
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px - 1rem);
`;

export const ServiceIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
`;

export const ArrayButton = styled.button`
  padding: 0;
  border: none;
  color: ${getColor("primary", 500)};
  background: none;
  cursor: pointer;
  font-size: 12px;
`;

export type PorWindow = {
  id: string;
  startTime: string;
  endTime: string;
  alertInterval?: number;
  alertEndTime?: string;
};

const EST = dayjs().tz("America/New_York").format("Z");

export const getPorWindowDateFromDateTime = (datetime: string) => {
  return new Date(`${datetime}${EST_TIMEZONE}`);
};

export type DataArrayCount = {
  label: string;
  value: number;
  subRows: { label: string; value: JSX.Element; count: number }[];
};

type RejectsCount = { rejects: { [key: string]: number } };
export const sortRejectArrayData = (a: RejectsCount, b: RejectsCount) => {
  return (
    Object.values(b.rejects).reduce((acc, curr) => acc + curr, 0) -
    Object.values(a.rejects).reduce((acc, curr) => acc + curr, 0)
  );
};
